export const footer = '#DC7A68'
// export const titleBackground = '#F6F1ED'
export const titleBackground = '#bcdded'
export const banner = '#E07460'
export const button = '#E26750'
export const clientBackground = '#FA7964'
export const hover = '#DB533A'
export const inputFocus = '#2684FF'
export const pricingColor = '#CFA16A'
export const pricingBackground = '#55BDCA'
export const footerBackground ='#316DAA'
export const pricingPassBackground = '#266783'