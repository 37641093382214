/** @jsxImportSource @emotion/react */
import * as React from "react"
import * as mq from "utils/media-queries"
import {
	Container,
	TitleSection,
	TitleContainer,
	TitleHeader,
	TitleContent,
	TitleImageContainer,
	ClientSection,
	ClientButtonContainer,
	ClientLink,
	ClientHeader,
	InfoContent,
	ColorStyledAnchor,
	Image,
	FlexRow,
	FlexColumn,
	FAQTitle,
	FAQQuestion,
	InfoContentContainer,
	InfoHeader,
	Column,
	ClientPhone,
	PricingCard,
	PricingCircle,
	PricingTitle,
	PricingLabel,
	ClientPhoneContainer,
} from "utils/lib"
import * as color from "utils/colors"
import daycareBanner from "assets/daycare-banner.jpg"
import daycareVector from "assets/daycare-vector.jpg"
import daycare1 from "assets/daycare-1.jpg"
import daycare2 from "assets/daycare-2.jpg"
import kitchen from "assets/kitchen.jpg"
import pottyStation from "assets/potty-station.jpg"

function DaycareTitle() {
	return (
		<TitleSection
			css={{
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundPosition: "right",
			}}
		>
			<TitleImageContainer src={daycareBanner} alt="daycare" right="-60%" />
			<TitleContainer
				css={{
					[mq.extraSmall]: {
						width: "100%",
					},
				}}
			>
				<TitleHeader>Daycare</TitleHeader>
				<TitleContent>Have a busy schedule? We're here to help.</TitleContent>
			</TitleContainer>
		</TitleSection>
	)
}

function DaycareInformation() {
	return (
		<div>
			<InfoContentContainer data-aos="fade-in">
				<Column>
					<InfoHeader>What To Expect</InfoHeader>
					<InfoContent>
						Our facility is made with sturdy and interchangeable equipment made
						exclusively for dogs. We have epoxy-coated floors, daily sanitizing
						system, climate-controlled environment, and an UV sterilizing air
						system.
					</InfoContent>
					<InfoContent css={{marginTop: "1rem"}}>
						All pets are socially tested before daycare.
					</InfoContent>
					<InfoContent css={{marginTop: "1rem"}}>
						You can view your pets with our 24 hour live feed camera.
					</InfoContent>
					<InfoContent css={{marginTop: "2rem", marginBottom: "2rem"}}>
						<ColorStyledAnchor
							href={`${process.env.REACT_APP_FACILITY_LIVE_FEED}`}
						>
							Facility Live Feed
						</ColorStyledAnchor>
					</InfoContent>

					<InfoContent
						css={{
							textAlign: "center",
							marginTop: "2rem",
						}}
					>
						<img
							css={{
								margin: "0 auto",
								maxWidth: "400px",
								[mq.extraSmall]: {
									maxWidth: "250px",
								},
								[mq.extraSmall]: {
									maxWidth: "250px",
								},
								[mq.medium]: {},
								[mq.large]: {
									marginTop: "4rem",
									marginBottom: "4rem",
								},
							}}
							src={daycareVector}
							alt="grooming-vector"
						/>
					</InfoContent>
				</Column>
			</InfoContentContainer>

			<InfoContentContainer>
				<FlexRow>
					<FlexColumn data-aos="fade-in">
						<InfoHeader css={{marginTop: "0"}}>Pricing</InfoHeader>
						<InfoContent css={{marginBottom: "1rem"}}>
							We provide half or full day daycare and playtime.
						</InfoContent>
						<InfoContent>
							<FlexRow
								css={{
									[mq.extraSmall]: {
										marginLeft: "0rem",
										marginRight: "0rem",
									},
									[mq.small]: {
										marginLeft: "0rem",
										marginRight: "0rem",
									},
								}}
							>
								<PricingCard orientation="left">
									<PricingTitle>Half Day</PricingTitle>
									<PricingCircle>35</PricingCircle>
									<PricingLabel>5 HOUR DAYCARE</PricingLabel>
								</PricingCard>
								<PricingCard orientation="right">
									<PricingTitle>Full Day</PricingTitle>
									<PricingCircle>45</PricingCircle>
									<PricingLabel>10 HOUR DAYCARE</PricingLabel>
								</PricingCard>
							</FlexRow>
						</InfoContent>

						<InfoHeader css={{marginTop: "1rem"}}>Pass Pricing</InfoHeader>
						<InfoContent css={{marginBottom: "1rem"}}>
							We also provide 10 or 20 half or full day passes.
						</InfoContent>
						<InfoContent>
							<PricingCard css={{backgroundColor: color.pricingPassBackground}}>
								<PricingTitle>Half Day Passes</PricingTitle>
								<FlexRow>
									<FlexColumn>
										<PricingCircle css={{fontSize: "1.5rem"}}>
											350
										</PricingCircle>
										<PricingLabel>10 HALF DAY PASS</PricingLabel>
									</FlexColumn>
									<FlexColumn>
										<PricingCircle css={{fontSize: "1.5rem"}}>
											700
										</PricingCircle>
										<PricingLabel>20 HALF DAY PASS</PricingLabel>
									</FlexColumn>
								</FlexRow>
							</PricingCard>
						</InfoContent>

						<InfoContent>
							<PricingCard css={{backgroundColor: color.pricingPassBackground}}>
								<PricingTitle>Full Day Passes</PricingTitle>
								<FlexRow>
									<FlexColumn>
										<PricingCircle css={{fontSize: "1.5rem"}}>
											450
										</PricingCircle>
										<PricingLabel>10 FULL DAY PASS</PricingLabel>
									</FlexColumn>
									<FlexColumn>
										<PricingCircle css={{fontSize: "1.5rem"}}>
											900
										</PricingCircle>
										<PricingLabel>20 FULL DAY PASS</PricingLabel>
									</FlexColumn>
								</FlexRow>
							</PricingCard>
						</InfoContent>
					</FlexColumn>
				</FlexRow>
				<FlexRow>
					<FlexColumn orientation="left">
						<Image src={daycare1} alt="daycare" />
					</FlexColumn>
					<FlexColumn orientation="right">
						<Image src={daycare2} alt="daycare" />
					</FlexColumn>
				</FlexRow>
			</InfoContentContainer>
		</div>
	)
}

function FacilityAmenities() {
	return (
		<InfoContentContainer>
			<FlexRow>
				<InfoHeader css={{width: "100%"}}>Facility Amenities</InfoHeader>
			</FlexRow>
			<FlexRow>
				<FlexColumn orientation="left">
					<Image data-aos="fade-in" src={kitchen} alt="kitchen" />
				</FlexColumn>
				<FlexColumn orientation="right">
					<Image data-aos="fade-in" src={pottyStation} alt="pottyStation" />
				</FlexColumn>
			</FlexRow>
		</InfoContentContainer>
	)
}

function DaycareFAQs() {
	return (
		<InfoContentContainer data-aos="fade-in">
			<Column>
				<FAQTitle />
				<FAQQuestion
					question={`Are there any requirements for daycare?`}
					answer={`All pets must be up to date on all vaccinations and clear of any parasites.`}
				/>
				<FAQQuestion
					question={`Do you use kennels or cages?`}
					answer={`No. All pets are placed in our play areas and allowed to stay with dogs of equal size.`}
				/>
				<FAQQuestion
					question={`Do you provide food?`}
					answer={`No. We do not provide food or medication. Food and medication can brought in and administered by our staff. We will only do oral and topical medication (no injections).`}
				/>
				<FAQQuestion
					question={`What if my dog needs to go potty?`}
					answer={`We have potty areas in the back of each play area.`}
				/>
				<FAQQuestion
					question={`Is there someone watching my dog during daycare?`}
					answer={`Our play areas are staffed the entire time.`}
				/>
				<FAQQuestion
					question={`Will my pass expire?`}
					answer={`No. Your pass will not expire. Multiple pets can be added to a single pass.`}
				/>
			</Column>
		</InfoContentContainer>
	)
}

function DaycareScreen() {
	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Container>
			<DaycareTitle />

			<ClientSection>
				<ClientHeader>BOOK YOUR APPOINTMENT</ClientHeader>
				<ClientButtonContainer>
					<ClientLink to="/clients/new">New Client Application</ClientLink>
					<ClientLink to="https://pawpartner.com/pz/book?p=268457">
						Returning Client
					</ClientLink>
					<ClientPhoneContainer>
						<ClientPhone />
					</ClientPhoneContainer>
				</ClientButtonContainer>
			</ClientSection>

			<DaycareInformation />
			<FacilityAmenities />
			<DaycareFAQs />
		</Container>
	)
}

export {DaycareScreen}
