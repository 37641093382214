/** @jsxImportSource @emotion/react */
import RegularReaptcha from 'reaptcha'
import * as mq from 'utils/media-queries'

// Public site key 
function Reaptcha({isVerified, captchaRef, onVerify}) {
  return (
    <div
      css={{
        marginTop: '1rem',
        width: '200px',
      }}
    >
      { isVerified ?
        null
        : 
        <RegularReaptcha
          css={{
            [mq.extraSmall]: {
              transformOrigin: '0 0',
              transform: 'scale(0.89)',
            }
          }}
          sitekey={process.env.REACT_APP_CAPTCHA_KEY}
          size='normal'
          ref={captchaRef}
          onVerify={onVerify}
        />
      }
    </div>
  )
}

export {Reaptcha}