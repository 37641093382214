/** @jsxImportSource @emotion/react */
import * as React from 'react'
import * as mq from 'utils/media-queries'
import * as color from 'utils/colors'
import {
	Container,
	TitleSection,
	TitleContainer,
	TitleHeader,
	TitleContent,
	TitleImageContainer,
	ClientSection,
	ClientHeader,
	ClientButtonContainer,
	ClientLink,
	InfoContent,
	BoardingRoomContainer,
	BoardingRoomImage,
	BoardingRoomInfo,
	BoardingRoomName,
	BoardingRoomContent,
	FAQTitle,
	FAQQuestion,
	InfoHeader,
	InfoContentContainer,
	Column,
	ClientPhone,
	FlexRow,
	FlexColumn,
	Image,
	ClientPhoneContainer,
} from 'utils/lib'
import boardingBanner from 'assets/boarding-banner.jpg'
import boardingVector from 'assets/boarding-vector.png'
import hacienda1 from 'assets/hacienda-1.jpg'

import westPlace from 'assets/west-place.jpg'
import westRidge from 'assets/west-ridge.jpg'
import westTown from 'assets/west-town.jpg'

import fairview1 from 'assets/fairview-1.jpg'
import cottage1 from 'assets/cottage-1.jpg'
import villa1 from 'assets/villa-1.jpg'
import orchid1 from 'assets/orchid-1.jpg'
import manor1 from 'assets/manor-1.jpg'

import kitchen from 'assets/kitchen.jpg'
import pottyStation from 'assets/potty-station.jpg'
import camera from 'assets/camera.jpg'
import cleaning from 'assets/cleaning.jpg'
import gallery1 from 'assets/boarding-1.jpg'
import gallery2 from 'assets/boarding-2.jpg'

function BoardingTitle() {
	return (
		<TitleSection
			css={{
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'contain',
			}}
		>
			<TitleContainer
				css={{
					[mq.extraSmall]: {
						width: '100%',
					},
				}}
			>
				<TitleHeader>Boarding</TitleHeader>
				<TitleContent>
					Our facility is staffed 24 hours a day with loving and experienced
					staff.
				</TitleContent>
			</TitleContainer>
			<TitleImageContainer src={boardingBanner} alt="boarding" />
		</TitleSection>
	)
}

function BoardingGeneralInformation() {
	return (
		<InfoContentContainer data-aos="fade-in">
			<Column>
				<InfoHeader>Boarding Facility</InfoHeader>
				<InfoContent>· 24 hour climate control</InfoContent>
				<InfoContent>· UV air sterilization</InfoContent>
				<InfoContent>· Epoxy-coated floors for sanitation</InfoContent>
				<InfoContent>· Wet/dry sanitizing system</InfoContent>
				<InfoContent>· Anti-bacterial coated walls</InfoContent>
				<InfoContent css={{marginTop: '1rem'}}>
					Your dog spends 10 hours a day or more playing with other dogs of
					similar size. All guests are socially tested before boarding and
					daycare. For puppies and out of town guests, please call for details
					on social testing options.
				</InfoContent>
			</Column>
		</InfoContentContainer>
	)
}

function StandardBoardingRoomHeader() {
	return (
		<InfoContentContainer css={{marginTop: '40px'}}>
			<Column>
				<InfoHeader>Standard Boarding</InfoHeader>
				<InfoContent>
					All day daycare of 10 hours or more is included in standard boarding.
					All units include a cozy/thick bed.
				</InfoContent>
			</Column>
		</InfoContentContainer>
	)
}

function StandardBoardingRooms() {
	return (
		<InfoContent>
			<Column>
				<BoardingRoomContainer data-aos="fade-in">
					<BoardingRoomImage
						orientation="left"
						src={westPlace}
						alt="west-place"
					/>
					<BoardingRoomInfo orientation="right">
						<BoardingRoomName>West Place</BoardingRoomName>
						<BoardingRoomContent>
							Our smallest unit ideal for a small dog.
						</BoardingRoomContent>
						<BoardingRoomContent>
							$63.00
							<div>(includes all day daycare)</div>
						</BoardingRoomContent>
					</BoardingRoomInfo>
				</BoardingRoomContainer>

				<BoardingRoomContainer data-aos="fade-in">
					<BoardingRoomInfo orientation="left">
						<BoardingRoomName>West Ridge</BoardingRoomName>
						<BoardingRoomContent>
							A great option for a medium sized dog.
						</BoardingRoomContent>
						<BoardingRoomContent>
							$68.00
							<div>(includes all day daycare)</div>
						</BoardingRoomContent>
					</BoardingRoomInfo>
					<BoardingRoomImage
						orientation="right"
						src={westRidge}
						alt="west-ridge"
					/>
				</BoardingRoomContainer>

				<BoardingRoomContainer data-aos="fade-in">
					<BoardingRoomImage
						orientation="left"
						src={westTown}
						alt="west-town"
					/>
					<BoardingRoomInfo orientation="right">
						<BoardingRoomName>West Town</BoardingRoomName>
						<BoardingRoomContent>
							Our large unit for bigger pets.
						</BoardingRoomContent>

						<BoardingRoomContent>
							$78.00
							<div>(includes all day daycare)</div>
						</BoardingRoomContent>
					</BoardingRoomInfo>
				</BoardingRoomContainer>
			</Column>
		</InfoContent>
	)
}

function LuxuryBoardingRoomHeader() {
	return (
		<InfoContentContainer>
			<Column>
				<InfoHeader>Luxury Boarding</InfoHeader>
				<InfoContent>
					All rooms include all day daycare of 10 hours or more. Additional dogs
					in the same room are $70.00 per pet.
				</InfoContent>
			</Column>
		</InfoContentContainer>
	)
}

function LuxuryBoardingRooms() {
	return (
		<InfoContent>
			<Column>
				<BoardingRoomContainer data-aos="fade-in">
					<BoardingRoomInfo orientation="left">
						<BoardingRoomName>Manor Room</BoardingRoomName>
						<BoardingRoomContent>
							Our smallest room for one or two small dogs.
						</BoardingRoomContent>

						<BoardingRoomContent>
							Room Size 4' x 4' - $84.00
							<div>(includes all day daycare)</div>
						</BoardingRoomContent>
					</BoardingRoomInfo>

					<BoardingRoomImage
						orientation="right"
						src={manor1}
						alt="manor-room"
					/>
				</BoardingRoomContainer>

				<BoardingRoomContainer data-aos="fade-in">
					<BoardingRoomImage
						orientation="left"
						src={fairview1}
						alt="fairview-room"
					/>

					<BoardingRoomInfo orientation="right">
						<BoardingRoomName>Fairview Room</BoardingRoomName>
						<BoardingRoomContent>
							A great option for that in between dog size (one medium bed).
						</BoardingRoomContent>
						<BoardingRoomContent>
							Room Size 4' x 5' - $88.00
							<div>(includes all day daycare)</div>
						</BoardingRoomContent>
					</BoardingRoomInfo>
				</BoardingRoomContainer>

				<BoardingRoomContainer data-aos="fade-in">
					<BoardingRoomInfo orientation="left">
						<BoardingRoomName>Orchid Room</BoardingRoomName>
						<BoardingRoomContent>
							This room has one medium bed or two small beds.
						</BoardingRoomContent>
						<BoardingRoomContent>
							Room Size 4' x 6' - $92.00
							<div>(includes all day daycare)</div>
						</BoardingRoomContent>
					</BoardingRoomInfo>

					<BoardingRoomImage
						orientation="right"
						src={orchid1}
						alt="orchid-room"
					/>
				</BoardingRoomContainer>

				<BoardingRoomContainer data-aos="fade-in">
					<BoardingRoomImage
						orientation="left"
						src={cottage1}
						alt="cottage-room"
					/>

					<BoardingRoomInfo orientation="right">
						<BoardingRoomName>Cottage Room</BoardingRoomName>
						<BoardingRoomContent>
							This room is great for one large dog or two medium dogs.
						</BoardingRoomContent>
						<BoardingRoomContent>
							Room Size 4' x 8' - $98.00
							<div>(includes all day daycare)</div>
						</BoardingRoomContent>
					</BoardingRoomInfo>
				</BoardingRoomContainer>

				<BoardingRoomContainer data-aos="fade-in">
					<BoardingRoomInfo orientation="left">
						<BoardingRoomName>Villa Room</BoardingRoomName>
						<BoardingRoomContent>
							This is our second biggest room and able to have two larges beds
							or a combination of multiple bed sizes.
						</BoardingRoomContent>
						<BoardingRoomContent>
							Room Size 8' x 8' - $114.00
							<div>(includes all day daycare)</div>
						</BoardingRoomContent>
					</BoardingRoomInfo>

					<BoardingRoomImage
						orientation="right"
						src={villa1}
						alt="villa-room"
					/>
				</BoardingRoomContainer>

				<BoardingRoomContainer data-aos="fade-in">
					<BoardingRoomImage
						orientation="left"
						src={hacienda1}
						alt="hacienda-room"
					/>
					<BoardingRoomInfo orientation="right">
						<BoardingRoomName>Hacienda Room</BoardingRoomName>
						<BoardingRoomContent>
							This is our biggest room and able to have multiple beds and
							accommodate dogs of multiple sizes.
						</BoardingRoomContent>
						<BoardingRoomContent>
							Room Size 10' x 10' - $124.00
							<div>(includes all day daycare)</div>
						</BoardingRoomContent>
					</BoardingRoomInfo>
				</BoardingRoomContainer>
			</Column>
		</InfoContent>
	)
}

function BoardingFAQs() {
	return (
		<InfoContentContainer data-aos="fade-in">
			<Column>
				<FAQTitle />
				<FAQQuestion
					question={`Will there be staff on-site 24 hours a day?`}
					answer={`Our 24 hour staff will clean and check on your dogs throughout the night.`}
				/>
				<FAQQuestion
					question={`Are there any restrictions for boarding?`}
					answer={`There are no age limits or disabilities restrictions. Guest must be able to get along with other pets of similar size during the day. All pets must be up to date on vaccinations and spayed/neutered after a certain age.`}
				/>
				<FAQQuestion
					question={`What if my pet requires medication?`}
					answer={`We will administer oral and topical prescriptions, but we cannot perform any injections.`}
				/>
				<FAQQuestion
					question={`What about my dog's feeding schedule/food?`}
					answer={`We have a full kitchen with capabilities to hold frozen or cold food for our guests. Specially required feeding bowls may be provided. We do have all sizes of stainless steel bowls for feeding and water. Guests have 24 hour access to fresh filtered water in their rooms and play areas. Water is changed constantly throughout the day.`}
				/>
				<FAQQuestion
					question={`Do I need to bring anything else for my dog?`}
					answer={`Guest may bring their own blankets, toys or that special item they want to have in there room. We provide blankets and beds for our guest.`}
				/>
			</Column>
		</InfoContentContainer>
	)
}

function Gallery() {
	return (
		<InfoContentContainer>
			<Column>
				<InfoHeader
					css={{
						marginBottom: '3rem',
						borderBottom: `1px solid ${color.banner}`,
					}}
				/>
			</Column>
			<FlexRow>
				<FlexColumn orientation="left">
					<Image src={gallery1} alt={'boarding-guest'} />
				</FlexColumn>
				<FlexColumn orientation="right">
					<Image src={gallery2} alt={'boarding-guests'} />
				</FlexColumn>
			</FlexRow>
		</InfoContentContainer>
	)
}

function FacilityFeatures() {
	return (
		<InfoContentContainer>
			<Column>
				<InfoHeader>Facility Amenities</InfoHeader>
			</Column>
			<FlexRow>
				<FlexColumn orientation="left">
					<Image data-aos="fade-in" src={kitchen} alt="kitchen" />
				</FlexColumn>
				<FlexColumn orientation="right">
					<Image data-aos="fade-in" src={pottyStation} alt="potty-station" />
				</FlexColumn>
			</FlexRow>
			<FlexRow>
				<FlexColumn orientation="left">
					<Image data-aos="fade-in" src={camera} alt="camera" />
				</FlexColumn>
				<FlexColumn orientation="right">
					<Image data-aos="fade-in" src={cleaning} alt="cleaning" />
				</FlexColumn>
			</FlexRow>
		</InfoContentContainer>
	)
}

function BoardingScreen() {
	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Container>
			<BoardingTitle />

			<ClientSection>
				<ClientHeader>BOOK YOUR APPOINTMENT</ClientHeader>
				<ClientButtonContainer>
					<ClientLink to="/clients/new">New Client Application</ClientLink>
					<ClientLink to="https://pawpartner.com/pz/book?p=268457">
						Returning Client
					</ClientLink>
					<ClientPhoneContainer>
						<ClientPhone />
					</ClientPhoneContainer>
				</ClientButtonContainer>
			</ClientSection>

			<BoardingGeneralInformation />

			<LuxuryBoardingRoomHeader />
			<LuxuryBoardingRooms />

			<StandardBoardingRoomHeader />
			<StandardBoardingRooms />

			<FacilityFeatures />
			<Gallery />

			<InfoContent>
				<img
					css={{
						margin: '2rem auto',
						maxWidth: '300px',
						[mq.medium]: {
							maxWidth: '400px',
							marginTop: '3rem',
							marginBottom: '3rem',
						},
						[mq.large]: {
							maxWidth: '400px',
							marginTop: '4rem',
							marginBottom: '4rem',
						},
					}}
					src={boardingVector}
					alt="boarding-vector"
				/>
			</InfoContent>
			<BoardingFAQs />
		</Container>
	)
}

export {BoardingScreen}
