/** @jsxImportSource @emotion/react */
import * as React from 'react'
import * as mq from 'utils/media-queries'
import * as color from 'utils/colors'
import {useForm, Controller} from 'react-hook-form'
import {
	Container,
	InfoContentContainer,
	InfoHeader,
	Form,
	Input,
	Label,
	FormGroup,
	SubmitButton,
	Select,
	InputError,
	SelectContainer,
	TextArea,
	ControllerFullContainer,
	ControllerHalfContainer,
} from 'utils/lib'
import {Reaptcha} from 'components/reaptcha'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {register} from 'utils/register'

const genderOptions = [
	{value: 'male', label: 'Male'},
	{value: 'female', label: 'Female'},
]

const fixedOptions = [
	{value: 'yes', label: 'Yes, my pet is neutered or spayed.'},
	{value: 'no', label: 'No, my pet is not neutered or spayed.'},
]

// const boardedOptions = [
// 	{value: 'yes', label: 'Yes, my pet has stayed at a boarding facility.'},
// 	{
// 		value: 'no',
// 		label: 'No, my pet has not stayed at a boarding facility before.',
// 	},
// ]

const groupPlayOptions = [
	{value: 'yes', label: 'Yes, my pet has played in a group before.'},
	{value: 'no', label: 'No, my pet has not played in a group before.'},
]

const serviceOptions = [
	{value: 'daycare', label: 'Daycare Service'},
	{value: 'boarding', label: 'Boarding Service'},
	{value: 'training', label: 'Training Service'},
]

function NewClientForm({
	captchaRef,
	captchaToken,
	onVerify,
	onSubmit,
	message,
	buttonText,
}) {
	const {
		register,
		handleSubmit,
		control,
		formState: {errors},
	} = useForm()
	const [isSubmitted, setIsSubmitted] = React.useState(false)
	const [startDate] = React.useState(new Date())

	return (
		<Form
			onSubmit={handleSubmit((form) => {
				if (!isSubmitted) {
					onSubmit(form)
					setIsSubmitted(true)
				}
			})}
		>
			<FormGroup>
				<Input
					name="firstName"
					label="First Name"
					register={{
						...register('firstName', {
							required: 'First name is required.',
							minLength: {
								value: '2',
								message: 'First name must be at least two characters.',
							},
							maxLength: {
								value: '30',
								message: 'First name is too long.',
							},
							pattern: {
								value: /^[A-Za-z-]+$/,
								message: 'Name cannot contain numbers or symbols.',
							},
						}),
					}}
					errors={errors}
				/>

				<Input
					css={{marginLeft: '1rem'}}
					name="lastName"
					label="Last Name"
					register={{
						...register('lastName', {
							required: 'Last name is required.',
							minLength: {
								value: '2',
								message: 'Last name must be at least two characters.',
							},
							maxLength: {
								value: '30',
								message: 'Last name is too long.',
							},
							pattern: {
								value: /^[a-zA-Z-]+$/,
								message: 'Name cannot contain numbers or symbols.',
							},
						}),
					}}
					errors={errors}
				/>
			</FormGroup>

			<FormGroup>
				<Input
					name="email"
					label="Email"
					register={{
						...register('email', {
							required: 'Email is required.',
							pattern: {
								value: /\S+@\S+\.\S+/,
								message: 'Please enter a valid email.',
							},
						}),
					}}
					errors={errors}
				/>

				<Input
					css={{marginLeft: '1rem'}}
					name="phoneNumber"
					label="Phone Number"
					register={{
						...register('phoneNumber', {
							required: 'Phone Number is required.',
							pattern: {
								// Phone number format: Optional +, parenthesis, dashes, periods, or spaces
								value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
								message: 'Valid phone number is required.',
							},
						}),
					}}
					errors={errors}
				/>
			</FormGroup>

			<FormGroup>
				<Input
					label="Address"
					name="address"
					register={{
						...register('address', {
							required: 'Address is required.',
							minLength: {
								value: '2',
								message: 'Address is required.',
							},
							pattern: {
								value: /^[A-Za-z0-9-\s.]+$/,
								message: 'This cannot have symbols.',
							},
						}),
					}}
					errors={errors}
				/>
			</FormGroup>

			<FormGroup>
				<Input
					label="City"
					name="city"
					register={{
						...register('city', {
							required: 'City is required.',
							minLength: {
								value: '2',
								message: 'City is required.',
							},
							pattern: {
								value: /^[A-Za-z-\s.]+$/,
								message: 'This cannot have symbols or numbers',
							},
						}),
					}}
					errors={errors}
				/>
				<Input
					css={{marginLeft: '1rem'}}
					label="State"
					name="state"
					value="CA"
					register={{
						...register('state', {
							required: 'State is required.',
							minLength: {
								value: '2',
								message: 'State is required',
							},
							pattern: {
								value: /^[A-Za-z\s]+$/,
								message: 'This cannot have symbols or numbers.',
							},
						}),
					}}
					errors={errors}
				/>
			</FormGroup>

			<FormGroup>
				<Input
					label="Zip Code"
					name="zipCode"
					register={{
						...register('zipCode', {
							required: 'Zip is required.',
							minLength: {
								value: '2',
								message: 'Zip is required',
							},
							pattern: {
								value: /^[0-9-]+$/,
								message: 'This cannot have symbols or characters.',
							},
						}),
					}}
					errors={errors}
				/>
			</FormGroup>

			<FormGroup>
				<Input
					name="petName"
					label="Pet's Name"
					register={{
						...register('petName', {
							required: 'Pet name is required.',
							minLength: {
								value: '2',
								message: 'Pet name must be at least two characters.',
							},
							maxLength: {
								value: '30',
								message: 'Pet name is too long.',
							},
							pattern: {
								value: /^[A-Za-z-\s]+$/,
								message: 'Name cannot contain numbers or symbols.',
							},
						}),
					}}
					errors={errors}
				/>
				<Input
					css={{marginLeft: '1rem'}}
					label="Breed"
					name="breed"
					register={{
						...register('breed', {
							required: 'Pet breed is required.',
							minLength: {
								value: '2',
								message: 'Pet breed is required.',
							},
							maxLength: {
								value: '30',
								message: 'Pet breed is too long.',
							},
							pattern: {
								value: /^[A-Za-z-\s]+$/,
								message: 'Breed cannot contain numbers or symbols.',
							},
						}),
					}}
					errors={errors}
				/>
			</FormGroup>

			<FormGroup>
				<Controller
					control={control}
					name="gender"
					rules={{required: true}}
					defaultValue={genderOptions[0]}
					render={({field: {onChange, value}}) => (
						<SelectContainer>
							<Select
								name="gender"
								label="Pet's Gender"
								options={genderOptions}
								value={value}
								onChange={onChange}
								errors={errors}
							/>
							{errors.gender && (
								<InputError>Please select a gender.</InputError>
							)}
						</SelectContainer>
					)}
				/>

				<Controller
					control={control}
					name="fixed"
					rules={{required: true}}
					defaultValue={fixedOptions[0]}
					render={({field: {onChange, value}}) => (
						<SelectContainer css={{marginLeft: '1rem'}}>
							<Select
								name="fixed"
								label="Neutered/Spayed"
								options={fixedOptions}
								value={value}
								onChange={onChange}
								errors={errors}
							/>
							{errors.fixed?.type === 'required' && (
								<InputError>
									Please select whether your pet is fixed.
								</InputError>
							)}
						</SelectContainer>
					)}
				/>
			</FormGroup>

			<FormGroup>
				<Input
					label="Pet's Age"
					placeholder={'include years or months'}
					name="age"
					register={{
						...register('age', {
							required: 'Pet age is required.',
							pattern: {
								value: /^[0-9\s]+(year|years|months|month)$/,
								message: 'Please indicate years or months.',
							},
						}),
					}}
					errors={errors}
				/>
				<Input
					css={{marginLeft: '1rem'}}
					label="Pet Weight"
					name="weight"
					register={{
						...register('weight', {
							required: 'Pet weight is required.',
							pattern: {
								value: /^[0-9\s.]+(lbs|lbs.)?$/,
								message: 'Please provide the weight in lbs.',
							},
						}),
					}}
					errors={errors}
				/>
			</FormGroup>

			{/* <FormGroup> */}
			{/* <Input
					label="Veterinarian"
					name="veterinarian"
					register={{
						...register("veterinarian", {
							required: "Veterinarian is required.",
							minLength: {
								value: "2",
								message: "Veterinarian is required",
							},
							maxLength: {
								value: "40",
								message: "Veterinarian is too long.",
							},
							pattern: {
								value: /^[A-Za-z\s,.-]+$/,
								message: "Vet cannot contain numbers or symbols.",
							},
						}),
					}}
					errors={errors}
				/> */}

			{/* <ControllerHalfContainer orientation="right">
					<Controller
						control={control}
						name="hasBoarded"
						rules={{required: true}}
						defaultValue={boardedOptions[0]}
						render={({field: {onChange, value}}) => (
							<SelectContainer>
								<Select 
									name="hasBoarded"
									label="Has Boarded?"
									options={boardedOptions}
									value={value}
									onChange={onChange}
									errors={errors}
								/>
								{errors.fixed?.type === "required" && (
									<InputError>Please select one.</InputError>
								)}
							</SelectContainer>
						)}
					/>
				</ControllerHalfContainer> */}
			{/* </FormGroup> */}

			<FormGroup>
				<ControllerFullContainer>
					<Controller
						control={control}
						name="hasGroupPlayed"
						rules={{required: true}}
						defaultValue={groupPlayOptions[0]}
						render={({field: {onChange, value}}) => (
							<SelectContainer>
								<Select
									name="hasGroupPlayed"
									label="Has my pet played in a group before?"
									options={groupPlayOptions}
									value={value}
									onChange={onChange}
									errors={errors}
								/>
								{errors.fixed?.type === 'required' && (
									<InputError>Please select one.</InputError>
								)}
							</SelectContainer>
						)}
					/>
				</ControllerFullContainer>
			</FormGroup>

			<FormGroup>
				<ControllerFullContainer>
					<Controller
						control={control}
						name="service"
						rules={{required: true}}
						defaultValue={serviceOptions[0]}
						render={({field: {onChange, value}}) => (
							<SelectContainer>
								<Select
									name="service"
									label="Service"
									options={serviceOptions}
									value={value}
									onChange={onChange}
									errors={errors}
								/>
								{errors.service && (
									<InputError>Please select a service.</InputError>
								)}
							</SelectContainer>
						)}
					/>
				</ControllerFullContainer>
			</FormGroup>

			<FormGroup>
				<ControllerHalfContainer orientation="left">
					<Controller
						control={control}
						name="dropOffDate"
						rules={{required: true}}
						defaultValue={startDate}
						render={({field: {onChange, value}}) => (
							<div>
								<Label>Drop Off Date</Label>
								<DatePicker
									css={{
										width: '100%',
										border: '1px solid hsl(0, 0%, 80%)',
										borderRadius: '4px',
										height: '38px',
										textIndent: '10px',
										fontFamily: 'Open Sans, sans-serif',
										fontSize: '1rem',
										boxSizing: 'border-box',
										':focus': {
											borderColor: color.inputFocus,
											boxShadow: `0 0 0 1px ${color.inputFocus}`,
										},
									}}
									selected={value}
									onChange={onChange}
									startDate={startDate}
									setStartDate={startDate}
									minDate={startDate}
								/>
								{errors.dropOffDate && (
									<InputError>Please select a date.</InputError>
								)}
							</div>
						)}
					/>
				</ControllerHalfContainer>

				<ControllerHalfContainer orientation="right">
					<Controller
						control={control}
						name="pickUpDate"
						rules={{required: true}}
						defaultValue={startDate}
						render={({field: {onChange, value}}) => (
							<div>
								<Label>Pick Up Date</Label>
								<DatePicker
									css={{
										width: '100%',
										border: '1px solid hsl(0, 0%, 80%)',
										borderRadius: '4px',
										height: '38px',
										textIndent: '10px',
										fontFamily: 'Open Sans, sans-serif',
										fontSize: '1rem',
										boxSizing: 'border-box',
										':focus': {
											borderColor: color.inputFocus,
											boxShadow: `0 0 0 1px ${color.inputFocus}`,
										},
									}}
									selected={value}
									onChange={onChange}
									setStartDate={startDate}
									minDate={startDate}
								/>
								{errors.pickUpDate && (
									<InputError>Please select a date.</InputError>
								)}
							</div>
						)}
					/>
				</ControllerHalfContainer>
			</FormGroup>

			<FormGroup>
				<TextArea
					name="healthConditions"
					label="Potential Health Conditions"
					register={{
						...register('healthConditions', {
							maxLength: {
								value: '200',
								message: 'Character limit reached.',
							},
							pattern: {
								value: /^[A-Za-z0-9-'\s.,]+$/,
								message: 'This cannot contain symbols.',
							},
						}),
					}}
					placeholder="Allergies, mobility, diabetes, etc."
					errors={errors}
				/>
			</FormGroup>

			<FormGroup>
				<TextArea
					name="comments"
					label="Anything else we should know about your pet?"
					register={{
						...register('comments', {
							maxLength: {
								value: '200',
								message: 'Character limit reached.',
							},
							pattern: {
								value: /^[A-Za-z0-9-'\s.,]+$/,
								message: 'This cannot contain symbols.',
							},
						}),
					}}
					errors={errors}
					placeholder="Any additional comments."
				/>
			</FormGroup>

			{/* Captcha */}
			<FormGroup>
				<Reaptcha captchaRef={captchaRef} onVerify={onVerify} />
			</FormGroup>

			<FormGroup
				css={{
					justifyContent: 'center',
				}}
			>
				<SubmitButton disabled={captchaToken === null} type="submit">
					{buttonText}
				</SubmitButton>
			</FormGroup>

			<FormGroup
				css={{
					marginTop: '2rem',
					height: '3rem',
					justifyContent: 'center',
				}}
			>
				{message}
			</FormGroup>
		</Form>
	)
}

function NewClientScreen() {
	const [captchaToken, setCaptchaToken] = React.useState(
		process.env.REACT_APP_ENVIRONMENT === 'development' ? 'test-token' : null
	)
	const [message, setMessage] = React.useState('')
	const [buttonText, setButtonText] = React.useState('SUBMIT')

	const onSubmit = (data) => {
		setButtonText('SENDING...')
		register({data, captchaToken})
			.then((res) => {
				captchaRef.current.reset()
				setMessage(res.message)
				setButtonText('SENT!')
			})
			.catch((error) => {
				captchaRef.current.reset()
			})
	}

	// Access Captcha Token
	const captchaRef = React.useRef()

	// Get captchaToken
	const onVerify = () => {
		captchaRef.current.getResponse().then((res) => {
			setCaptchaToken(res)
		})
	}

	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Container>
			<InfoContentContainer
				css={{
					boxShadow: '0px 1px 8px 0px rgba(150,150,150,1)',
					paddingBottom: '3rem',
					[mq.extraSmall]: {
						marginTop: '10rem',
					},
					[mq.small]: {
						marginTop: '10rem',
					},
					[mq.medium]: {
						marginTop: '10rem',
					},
					[mq.large]: {
						marginTop: '13rem',
					},
				}}
			>
				<InfoHeader
					css={{
						textAlign: 'center',
						width: '242px',
						paddingTop: '3rem',
						margin: '3rem auto',
					}}
				>
					Client Application
				</InfoHeader>
				<NewClientForm
					onVerify={onVerify}
					onSubmit={onSubmit}
					captchaRef={captchaRef}
					captchaToken={captchaToken}
					message={message}
					buttonText={buttonText}
				/>
			</InfoContentContainer>
		</Container>
	)
}

export {NewClientScreen}
