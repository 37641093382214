/** @jsxImportSource @emotion/react */
import * as React from 'react'
import * as mq from 'utils/media-queries'
import {
	Container,
	TitleSection,
	TitleContainer,
	TitleHeader,
	TitleContent,
	TitleImageContainer,
	Column,
	FAQTitle,
	FAQQuestion,
	Image,
	FlexRow,
	FlexColumn,
	InfoContentContainer,
	InfoHeader,
	InfoSubHeader,
	InfoContent,
	ClientSection,
	ClientHeader,
	ClientButtonContainer,
	ClientButton,
	ClientPhone,
} from 'utils/lib'
import groomingImage from 'assets/grooming.jpg'
import bathtubSmall from 'assets/bathtub-small.jpg'
import bathtubLarge from 'assets/bathtub-large.jpg'
import groomingBanner from 'assets/grooming-banner.jpg'
import groomingVector from 'assets/grooming-vector.jpg'
import barker from 'assets/barker.jpg'

function GroomingTitle() {
	return (
		<TitleSection
			css={{
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
			}}
		>
			<TitleContainer
				css={{
					[mq.extraSmall]: {
						width: '100%',
					},
				}}
			>
				<TitleHeader
					css={{
						[mq.extraSmall]: {
							fontSize: '3rem',
						}
					}}
				>Grooming</TitleHeader>
				<TitleContent>
					Grooming is a vital part in the well-being and healthiness of your dog.
				</TitleContent>
			</TitleContainer>
			<TitleImageContainer 
				src={groomingBanner} 
				alt="grooming" 
				left='-49%'
			/>
		</TitleSection>
	)
}

function GroomingInformation() {
	return (
		<InfoContentContainer
			css={{
				marginTop: '4rem',	
			}}
		>
			<FlexRow>
				<FlexColumn orientation="left">
					<InfoHeader data-aos='fade-in'>Grooming Package</InfoHeader>

					<InfoSubHeader data-aos='fade-in' data-aos-delay='100'>Standard Bath Package</InfoSubHeader>
					<InfoContent data-aos='fade-in' data-aos-delay='100'>· Shampoo Bath</InfoContent>
					<InfoContent data-aos='fade-in' data-aos-delay='100'>· Nail Sanding</InfoContent>
					<InfoContent data-aos='fade-in' data-aos-delay='100'>· Ear Cleaning</InfoContent>
					<InfoContent data-aos='fade-in' data-aos-delay='100'>· Gland Expression</InfoContent>

					<InfoContent data-aos='fade-in' data-aos-delay='100' css={{ marginTop: '1rem' }}>For long hair dogs, this will also include a sanitary trim, and feet and facial fur trim.</InfoContent>
					
					<InfoContent data-aos='fade-in' data-aos-delay='100' css={{ marginTop: '1rem' }}>For a full groom, this will also include a full body cut (to breed standards) and sanitary trim.</InfoContent>
				</FlexColumn>
				<FlexColumn orientation="right">
					<Image data-aos='fade-in' src={barker} alt="grooming" />
				</FlexColumn>
			</FlexRow>
		</InfoContentContainer>
	)
}

function GroomingAdditionals() {
	return (
		<InfoContentContainer>
			<FlexRow>
				<FlexColumn
					orientation="left"
					css={{
						[mq.extraSmall]: {
							order: '1',
						},
						[mq.small]: {
							order: '1',
						},
					}}
				>
					<FlexRow 
						css={{ 
							[mq.extraSmall]: {
								marginLeft: '0',
								marginRight: '0',
							},
							[mq.small]: {
								marginLeft: '0',
								marginRight: '0',
							},
							[mq.medium]: {
								marginTop: '0'
							},
							[mq.large]: {
								marginTop: '0'
							}
						}}
					>
						<Image data-aos='fade-in' src={groomingImage} alt="grooming" />
					</FlexRow>
					<FlexRow
						css={{
							[mq.extraSmall]: {
								marginLeft: '0',
								marginRight: '0',
							},
							[mq.small]: {
								marginLeft: '0',
								marginRight: '0',
							},
						}}
					>
						<FlexColumn	orientation="left">
							<Image data-aos='fade-in' data-aos-delay='100' src={bathtubSmall} alt="grooming" />
						</FlexColumn>
						<FlexColumn orientation="right">
							<Image data-aos='fade-in' data-aos-delay='100' src={bathtubLarge} alt="grooming" />
						</FlexColumn>
					</FlexRow>
				</FlexColumn>

				<FlexColumn
					orientation="right"
					css={{
						[mq.extraSmall]: {
							order: '0',
						},
						[mq.small]: {
							order: '0',
						},
					}}
				>
					<InfoHeader data-aos='fade-in'>Additional Services</InfoHeader>
					<InfoSubHeader data-aos='fade-in' data-aos-delay='100'>Teeth Brushing</InfoSubHeader>
					<InfoContent data-aos='fade-in' data-aos-delay='200' css={{marginBottom: '1rem'}}>
						Taking care of your dog's dental health is just as important as any other aspect of your dog's well-being.
					</InfoContent>
					<InfoSubHeader data-aos='fade-in' data-aos-delay='100'>Medicated Flea Baths</InfoSubHeader>
					<InfoContent data-aos='fade-in' data-aos-delay='200' css={{marginBottom: '1rem'}}>
						The best flea shampoos for dogs prevent flea from maturing. Many of our shampoos also include ingredients like oatmeal to soothe itchy skin.
					</InfoContent>
					<InfoSubHeader data-aos='fade-in' data-aos-delay='100'>De-Shedding</InfoSubHeader>
					<InfoContent data-aos='fade-in' data-aos-delay='200' css={{marginBottom: '1rem'}}>
						De-shedding aids in the natural shedding process. While it won't be able to remove all of the undercoat, it will help protect your pet from unexpected and painful mats.
					</InfoContent>
				</FlexColumn>
			</FlexRow>
		</InfoContentContainer>
	)
}

function GroomingFAQs() {
	return (
		<InfoContentContainer data-aos='fade-in'>
			<Column>
					<FAQTitle />
					<FAQQuestion
						question={`Do I need an appointment?`}
						answer={`Yes. We require appointments for grooming services. Please schedule with us in advance. On rare occasions, we can accommodate same day bath services. For nail trimming only, you can schedule same day or the day before.`}
					/>
					<FAQQuestion
						question={`What if my dog's fur is matted?`}
						answer={`There are extra costs for de-matting your pet, if brushing or shaving is required. We will not de-matt a pet if the matted fur is causing discomfort, or if brushing will irritate the skin.`}
					/>
					<FAQQuestion
						question={`Can I bring my own shampoo?`}
						answer={`Yes. We will use your shampoo if provided. We carry Natures Specialties Aloe and Plum shampoos and conditioners. We also carry their hypoallergenic and oatmeal shampoos.`}
					/>
			</Column>
		</InfoContentContainer>
	)
}

function GroomingScreen() {
	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Container>
			<GroomingTitle/>
			<ClientSection>
				<ClientHeader>BOOK YOUR APPOINTMENT</ClientHeader>
				<ClientButtonContainer>
					<ClientButton
						href={`mailto:${process.env.REACT_APP_EMAIL}`}
					>EMAIL</ClientButton>
					<ClientPhone/>
				</ClientButtonContainer>				
			</ClientSection>
			<GroomingInformation/>

			<GroomingAdditionals/>
			<img
				css={{
					margin: '0 auto',
					maxWidth: '400px',
					[mq.extraSmall]: {
						maxWidth: '250px',
					},
					[mq.extraSmall]: {
						maxWidth: '300px',
					},
				}}
				src={groomingVector}
				alt='grooming-vector'
			/>
			<GroomingFAQs/>
		</Container>
	)
}

export {GroomingScreen}
