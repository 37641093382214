/** @jsxImportSource @emotion/react */
import * as React from 'react'
import {keyframes} from '@emotion/react'
import {GoogleMap, useJsApiLoader, Marker} from '@react-google-maps/api'

const containerStyle = {
  width: 'auto',
  height: '400px',
}

const center = {
  lat: 33.93159731968089,
  lng: -117.95161215799868,
}

const mapAnimation = keyframes({
  '0%': {
    opacity: '0',   
  },
  '100%': {
    opacity: '1',   
  } 
})

function Map() {
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API
  })

  const [, setMap] = React.useState(null)
  const [zoom, setZoom] = React.useState(0)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds()
    map.fitBounds(bounds)
    setMap(map)

    // Imperatively pan map to center because this library often loses center on re-renders.  
    map.panTo(center)
  }, [])

  // Set zoom on first render since zoom gets lost on frequent re-renders.
  React.useEffect(() => {
    setTimeout(() => {
      setZoom(15)
    }, 300)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <div
      css={{
        backgroundColor: '#fff',
        animation: `1s ease-in-out ${mapAnimation} forwards`
      }}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker position={center}/>
      </GoogleMap>
    </div>
  ) : null
}

export default React.memo(Map)