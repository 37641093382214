/** @jsxImportSource @emotion/react */
import * as React from 'react'
import {useForm} from 'react-hook-form'
import * as mq from 'utils/media-queries'
import contactBanner from 'assets/contact-banner.jpg'
import {Container,TitleSection, TitleContainer,	TitleImageContainer,TitleHeader, TitleContent,	InfoContentContainer,	FlexRow,	FlexColumn,	InfoHeader,	InfoContent, InfoSubHeader, Input,  Form, FormGroup, SubmitButton, TextArea} from 'utils/lib'
import {Reaptcha} from 'components/reaptcha'
import {inquire} from 'utils/inquire'
import Map from 'components/map'

function AboutUsTitle() {
  return (
    <TitleSection>
      <TitleImageContainer src={contactBanner} alt="pampered-paws" />
      <TitleContainer
        css={{
          [mq.extraSmall]: {
            width: '100%',
          },
        }}
      >
        <TitleHeader>Contact Us</TitleHeader>
        <TitleContent>
          We strive to make our grooming schedule as convenient as possible. We offer extended days, hours, and early morning drop-offs.
        </TitleContent>
      </TitleContainer>
    </TitleSection>
  )
}


function Hours() {
  return (
    <div>
      <InfoHeader
        data-aos='fade-in'
        css={{
          margin: '1rem auto',
          [mq.extraSmall]: {
            width: '127px',
          },
          [mq.small]: {
            width: '160px',
          },
        }}
      >Hours</InfoHeader>
      <InfoContent data-aos='fade-in' css={{ marginBottom: '1rem'}}>
        <InfoSubHeader 
          css={{ 
            marginBottom: '0',
            fontFamily: 'TiemposHeadlineRegular, serif'
          }}
        >Daycare Hours</InfoSubHeader>
        <InfoContent>Monday through Saturday</InfoContent>
        <InfoContent>7:00 am - 7:00 pm</InfoContent>
      </InfoContent>
      <InfoContent data-aos='fade-in' css={{ marginBottom: '1rem'}}>
        <InfoSubHeader 
          css={{
            marginBottom: '0',
            fontFamily: 'TiemposHeadlineRegular, serif'
          }}
        >Boarding Hours</InfoSubHeader>
        <InfoContent>7 Days a Week</InfoContent>
        <InfoContent>7:00 am - 7:00 pm</InfoContent>
      </InfoContent>
      <InfoContent data-aos='fade-in' css={{ marginBottom: '1rem'}}>
        <InfoSubHeader 
          css={{ 
            marginBottom: '0',
            fontFamily: 'TiemposHeadlineRegular, serif'
          }}
        >Salon Hours</InfoSubHeader>
        <InfoContent>Tuesday Through Saturday</InfoContent>
        <InfoContent>8:00 am - 5:00 pm</InfoContent>
      </InfoContent>
    </div>
  )
}


function ContactUsForm({captchaRef, captchaToken, onVerify, onSubmit, message, buttonText}) {
  const {register, handleSubmit, formState: {errors}} = useForm()
  const [inquirySent, setInquirySent] = React.useState(false)
  
  return (
    <Form 
      onSubmit={
        handleSubmit((form) => {
          if (!inquirySent) {
            onSubmit(form)
            setInquirySent(true)
          } 
        })
      }
    >
      {/* First and Last Name */}
      <FormGroup>
        <Input
          name='firstName'
          label='First Name'
          register={{
            ...register('firstName', {
              required: 'First name is required.',
              minLength: {
                value: '2',
                message: 'Name must be at least two letters.',
              },
              maxLength: {
                value: '30',
                message: 'First name is too long.'
              },
              pattern: {
                value: /^[A-Za-z-\s]+$/,
                message: 'Name cannot contain numbers or symbols.'
              }
            }),
          }}
          errors={errors}
        />

        <Input
          css={{ marginLeft: '1rem', }}
          name="lastName"
          label='Last Name'
          register={{
            ...register('lastName', {
              required: 'Last name is required.',
              minLength: {
                value: '2',
                message: 'Name must be at least two letters.',
              },
              maxLength: {
                value: '30',
                message: 'Last name is too long.'
              },
              pattern: {
                value: /^[a-zA-Z-\s]+$/,
                message: 'Name cannot contain numbers or symbols.'
              }
            }),
          }}
          errors={errors}
        />
      </FormGroup>
      
      {/* Email and Phone */}
      <FormGroup>
        <Input
          name='email'
          label='Email'
          register={{
            ...register('email', {
              required: 'Email is required.',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Please enter a valid email."
              },
            })
          }}
          errors={errors}
        />

        <Input
          css={{ marginLeft: '1rem' }}
          name='phoneNumber'
          label='Phone Number'
          register={{
            ...register('phoneNumber', {
              required: 'Phone Number is required.',
              pattern: {
                // Phone number format: Optional +, parenthesis, dashes, periods, or spaces 
                value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
                message: 'Valid phone number is required.'
              }
            })
          }}
          errors={errors}
        />
      </FormGroup>
      
      {/* Comments */}
      <FormGroup>
        <TextArea
          name='comments'
          label='Comments'
          placeholder='Prices, hours, availability, etc.'
          register={{
            ...register('comments', {
              required: 'What would you like to ask?',
              minLength: {
                value: 2,
                message: 'What would you like to ask?'
              },
              maxLength: {
								value: '200',
								message: "Character limit reached."
							},
              pattern: {
								value: /^[A-Za-z0-9-'\s.,?]+$/,
								message: 'This cannot contain symbols.'
							}
            })
          }}
          errors={errors}
        />
      </FormGroup>
      
      <FormGroup>
        <Reaptcha
          captchaRef={captchaRef}
          onVerify={onVerify}
        />
      </FormGroup>

      {/* Submit Button */}
      <FormGroup
        css={{
          justifyContent: 'center',
        }}
      >
        <SubmitButton
          disabled={captchaToken === null}
          type='submit'
        >{buttonText}</SubmitButton>
      </FormGroup>

      <FormGroup css={{
        marginTop: '2rem',
        height: '3rem',
        justifyContent: 'center',
      }}>
        {message}
      </FormGroup>
    </Form>
  )
}

function ContactUsScreen() {
  const [captchaToken, setCaptchaToken] = React.useState(process.env.REACT_APP_ENVIRONMENT === 'development' ? 'test-token' : null)
  const [message, setMessage] = React.useState('')
  const [buttonText, setButtonText] = React.useState('INQUIRE')

  const onSubmit = (data) => {
    setButtonText('SENDING...')
    inquire({data, captchaToken}).then(res => {
      captchaRef.current.reset()
      setMessage(res.message)
      setButtonText('SENT!')
    }).catch(error => {
      captchaRef.current.reset()
    })
  }

  // Access Captcha Token
  const captchaRef = React.useRef()

  // Get captchaToken
  const onVerify = () => {
    captchaRef.current.getResponse().then(res => {
      setCaptchaToken(res)
    })
  }

	React.useEffect(() => {
    window.scrollTo(0, 0)
	}, [])

	return (
		<Container>
			<AboutUsTitle/>

			<InfoContentContainer>
				<FlexRow>
					<FlexColumn
            css={{
              [mq.extraSmall]: {
                textAlign: 'center',
              },
              [mq.small]: {
                textAlign: 'center',
              }
            }}
            orientation='left'
          >
						<Hours/>
					</FlexColumn>

					<FlexColumn
            orientation='right'
          >
            <Map/>
          </FlexColumn>
				</FlexRow>
			</InfoContentContainer>

      <InfoContentContainer
        css={{
          boxShadow: '0px 1px 8px 0px rgba(150,150,150,1)',
          paddingBottom: '2rem',
        }}
      >
        <FlexColumn>
          <InfoHeader
            css={{
              textAlign: 'center',
              width: '271px',
              margin: '3rem auto',
              paddingTop: '3rem',
            }}
          >Have a question?</InfoHeader>
          <ContactUsForm
            onVerify={onVerify}
            onSubmit={onSubmit}
            captchaRef={captchaRef}
            captchaToken={captchaToken}
            message={message}
            buttonText={buttonText}
          />
        </FlexColumn>
      </InfoContentContainer>
		</Container>
	)
}

export {ContactUsScreen}