/** @jsxImportSource @emotion/react */
import * as React from 'react'
import * as mq from 'utils/media-queries'
import * as color from 'utils/colors'
import {SocialMediaIcon} from 'utils/lib'
import yelpIcon from 'assets/yelp-icon.png'
import yelpIconHovered from 'assets/yelp-icon-hovered.png'
import facebookIcon from 'assets/facebook-icon.png'
import facebookIconHovered from 'assets/facebook-icon-hovered.png'
import instagramIcon from 'assets/instagram-icon.png'
import instagramIconHovered from 'assets/instagram-icon-hovered.png'

function SocialMedia() {
	return (
		<div
			css={{
				display: 'flex',
				[mq.medium]: {
					justifyContent: 'center',
				},
				[mq.large]: {
					justifyContent: 'center',
				},
			}}
		>
      <SocialMediaIcon
        src={yelpIcon}
        srcHovered={yelpIconHovered}
        href={process.env.REACT_APP_YELP_URL}
        width='50px'
        height='50px'
      />
      <SocialMediaIcon
        src={facebookIcon}
        srcHovered={facebookIconHovered}
        href={process.env.REACT_APP_FACEBOOK_URL}
        width='50px'
        height='50px'
        cssOverride={{
          marginLeft: '0.5rem',
          marginRight: '0.5rem',
        }}
      />
			<SocialMediaIcon
				src={instagramIcon}
				srcHovered={instagramIconHovered}
				href={process.env.REACT_APP_INSTAGRAM_URL}
				width='50px'
				height='50px'
				cssOverride={{
					marginRight: '0.5rem'
				}}
			/>
		</div>
	)
}

function Address() {
	return (
		<div
			css={{
				color: '#fff',
				textWrap: 'none',
				textOverflow: 'ellipsis',
				fontFamily: 'Lora, serif',
			}}
		>
      {/* Address Container */}
			<div
				css={{
					margin: '0 auto',
					fontSize: '1.0rem',
					[mq.extraSmall]: {
						fontSize: '1rem',
					},
				}}
			>
				<div>572 W La Habra Blvd</div>
				<div>La Habra, CA 90631</div>
			</div>
      {/* Phone and Email Container */}
			<div
				css={{
					margin: '1rem auto',
					fontSize: '1.0rem',
					[mq.extraSmall]: {
						fontSize: '1rem',
					},
				}}
			>
        {/* Phone */}
        <div css={{display: 'block'}}>
          <a
            css={{
              textDecoration: 'none',
              color: '#fff',
              margin: '0.5rem auto',
              display: 'inline-block',
							[mq.extraSmall]: {
								fontSize: '0.9rem',
							},
							[mq.small]: {},
            }}
            href={`tel:${process.env.REACT_APP_PHONE}`}
          >{process.env.REACT_APP_PHONE}</a>
        </div>

        {/* Email */}
        <div css={{display: 'block'}}>
          <a
            css={{
              textDecoration: 'none',
              color: '#fff',
              margin: '0.5rem auto',
              display: 'inline-block',
							[mq.extraSmall]: {
								fontSize: '0.9rem',
							},
							[mq.small]: {},
            }}
            href={`mailto:${process.env.REACT_APP_EMAIL}`}
          >{process.env.REACT_APP_EMAIL}</a>
        </div>
			</div>
		</div>
	)
}

function Footer() {
	const year = new Date().getFullYear()

	return (
		<div
			css={{
				minWidth: '320px',
				backgroundColor: color.footerBackground,
				color: '#fff',
				width: '100%',
				marginTop: 'auto',
				[mq.extraSmall]: {
					textAlign: 'left',
				},
				[mq.small]: {
					textAlign: 'left',
				},
			}}
		>
			<div
				css={{
					[mq.extraSmall]: {
						paddingLeft: '8.33%',
						paddingRight: '8.33%',
					},
					[mq.small]: {
						paddingLeft: '8.33%',
						paddingRight: '8.33%',
					},
					[mq.medium]: {},
					[mq.large]: {},

				}}
			>

				<div
					css={{
						textOverflow: 'ellipsis',
						fontFamily: 'PLayfair Display, serif',
						margin: '0 auto',
						marginTop: '2rem',
						[mq.extraSmall]: {
							fontSize: '1.25rem',
						},
						[mq.small]: {
							fontSize: '1.5rem',
						},
						[mq.medium]: {
							fontSize: '1.5rem',
						},
						[mq.large]: {
							fontSize: '1.5rem',
						},
					}}
				>Pampered Paws Salon & Spa</div>

				<Address />

				<hr
					css={{
						marginBottom: '1rem',
						color: '#fff',
						[mq.extraSmall]: {},
						[mq.small]: {},
						[mq.medium]: {
							width: '100px',
						},
						[mq.large]: {
							width: '100px', 
						},
					}}
				/>

				<SocialMedia />

				<div 
					css={{
						margin: '0.5rem auto',
						marginBottom: '2rem', 
						fontSize: '0.8rem'
					}}
				>Pampered Paws Salon & Spa {year}</div>
			</div>
		</div>
	)
}

export {Footer}
