/** @jsxImportSource @emotion/react */
import * as React from 'react'
import {keyframes} from '@emotion/react'
import * as mq from 'utils/media-queries'
import * as color from 'utils/colors'
import {Link, useMatch} from 'react-router-dom'
import NativeSelect from 'react-select'
import plusIcon from 'assets/plus-icon.png'
import {useInView} from 'react-intersection-observer'

function Container(props) {
	return (
		<div
			css={{
				width: '100%',
				minWidth: '320px',
				margin: '0 auto',
				[mq.extraSmall]: {},
				[mq.small]: {
					marginTop: '100px',
				},
				[mq.medium]: {
					marginTop: '100px',
				},
				[mq.large]: {
					marginTop: '100px',
				},
			}}
			{...props}
		/>
	)
}

// TitleSection contains TitleContainer and ImageContainer
function TitleSection(props) {
	return (
		<div
			css={{
				minWidth: '320px',
				display: 'flex',
				flexDirection: 'row',
				width: '100%',
				position: 'relative',
				minHeight: '365px',
			}}
			{...props}
		/>
	)
}

// Contains TitleHeader and Content
function TitleContainer(props) {
	return (
		<div
			css={{
				display: 'flex',
				flexDirection: 'column',
				flex: '1',
				justifyContent: 'center',
				fontFamily: 'Playfair Display, sans-serif',
				color: '#fff',
				zIndex: '2',
				minHeight: '365px',
				userSelect: 'none',
				[mq.extraSmall]: {
					position: 'absolute',
					top: '45%',
					transform: 'translateY(-50%)',
				},
				[mq.small]: {
					position: 'absolute',
					top: '50%',
					transform: 'translateY(-50%)',
				},
				[mq.medium]: {
					color: '#000',
				},
				[mq.large]: {
					color: '#000',
				},
			}}
			{...props}
		/>
	)
}

// Title at start of each page
function TitleHeader(props) {
	return (
		<div
			data-aos="fade-in"
			data-aos-delay="300"
			css={{
				fontFamily: 'Playfair Display, serif',
				marginLeft: '5rem',
				marginRight: '5rem',
				fontSize: '2rem',
				textAlign: 'left',
				[mq.extraSmall]: {
					textAlign: 'center',
					marginLeft: '8.33%',
					marginRight: '8.33%',
					fontSize: '3.5rem',
				},
				[mq.small]: {
					fontSize: '5rem',
				},
				[mq.medium]: {
					fontSize: '3.5rem',
				},
				[mq.large]: {
					fontSize: '4rem',
				},
			}}
			{...props}
		/>
	)
}

// Content under Title Header
function TitleContent(props) {
	return (
		<div
			data-aos="fade-in"
			data-aos-delay="500"
			css={{
				fontFamily: 'Open Sans, sans-serif',
				fontSize: '1.15rem',
				marginTop: '1rem',
				marginLeft: '5rem',
				marginRight: '5rem',
				textAlign: 'left',
				[mq.extraSmall]: {
					fontSize: '1rem',
					display: 'none',
				},
				[mq.small]: {
					fontSize: '1.15rem',
					marginTop: '2rem',
				},
			}}
			{...props}
		/>
	)
}

// Image used next to Title on each page
function TitleImageContainer({src, alt, left, right, ...props}) {
	return (
		<div
			css={{
				display: 'flex',
				flexDirection: 'column',
				flexBasis: '100%',
				flex: '1',
				position: 'relative',
				minHeight: '365px',
				[mq.extraSmall]: {
					minWidth: '320px',
					height: '100vh',
					minHeight: '500px',
					overflow: 'hidden',
				},
			}}
			{...props}
		>
			{/* Tint for smaller screens */}
			<div
				css={{
					backgroundColor: '#000',
					width: '100%',
					height: '100%',
					zIndex: '1',
					opacity: '0.1',
					[mq.extraSmall]: {
						position: 'absolute',
					},
					[mq.small]: {
						position: 'absolute',
					},
					[mq.medium]: {
						display: 'none',
					},
					[mq.large]: {
						display: 'none',
					},
				}}
			/>
			{/* Image */}
			<img
				css={{
					minWidth: '160px',
					width: '100%',
					height: '100%',
					[mq.extraSmall]: {
						width: 'auto',
						display: 'block',
						marginLeft: 'auto',
						marginRight: 'auto',
						minWidth: '320px',
						position: 'absolute',
						top: '-100%',
						bottom: '-100%',
						left: left ? left : '-100%',
						right: right ? right : '-100%',
						margin: 'auto',
					},
				}}
				src={src}
				alt={alt}
			/>
		</div>
	)
}

// Client section contains buttons to access in new client application form.
function ClientSection(props) {
	return (
		<div
			css={{
				backgroundColor: color.clientBackground,
				color: '#fff',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				height: '220px',
				[mq.extraSmall]: {
					height: '260px',
				},
				[mq.small]: {
					height: '260px',
				},
			}}
			{...props}
		/>
	)
}

// Used in Client Section
function ClientHeader(props) {
	return (
		<div
			data-aos="fade-in"
			css={{
				fontSize: '2rem',
				marginLeft: '1rem',
				marginRight: '1rem',
				marginTop: '1rem',
				marginBottom: '1rem',
				fontFamily: 'Lora, serif',
				[mq.extraSmall]: {
					fontSize: '1.25rem',
					marginTop: '0rem',
					marginBottom: '0.5rem',
				},
			}}
			{...props}
		/>
	)
}

// Container for new client application buttons
function ClientButtonContainer(props) {
	return (
		<div
			data-aos="fade-in"
			css={{
				width: '100%',
				margin: '0rem auto',
			}}
			{...props}
		/>
	)
}

// "New Client Application" Button
function ClientLink(props, {to}) {
	const [onHover, setOnHover] = React.useState(false)

	return (
		<Link
			css={{
				outline: 'none',
				userSelect: 'none',
				textDecoration: 'none',
				borderRadius: '40px',
				backgroundColor: onHover ? '#e9e9e9' : '#fff',
				color: '#000',
				fontFamily: 'Lora, serif',
				padding: '0.5rem 1.5rem',
				margin: '1rem',
				transition: '0.3s ease-in-out',
				boxShadow: '0px 1px 5px 0px #c1540b',
				justifyContent: 'center',
				[mq.extraSmall]: {
					// display: 'flex',
					display: 'inline-block',
					margin: '0.75rem',
				},
				[mq.small]: {},
			}}
			to={to}
			onMouseOver={() => setOnHover(true)}
			onMouseOut={() => setOnHover(false)}
			{...props}
		/>
	)
}

// Email/call button
function ClientButton({href, alt, children, ...props}) {
	const [onHover, setOnHover] = React.useState(false)

	return (
		<a
			css={{
				outline: 'none',
				userSelect: 'none',
				textDecoration: 'none',
				borderRadius: '40px',
				backgroundColor: onHover ? '#e9e9e9' : '#fff',
				color: '#000',
				fontFamily: 'Lora, serif',
				padding: '0.5rem 1.5rem',
				margin: '1rem',
				transition: '0.3s ease-in-out',
				boxShadow: '0px 1px 5px 0px #c1540b',
			}}
			onMouseOver={() => setOnHover(true)}
			onMouseOut={() => setOnHover(false)}
			href={href}
			alt={alt}
			{...props}
		>
			{children}
		</a>
	)
}

function ClientPhoneContainer(props) {
	return (
		<div
			css={{
				marginTop: '1rem',
				[mq.extraSmall]: {
					marginTop: '0.5rem',
				},
				[mq.small]: {
					marginTop: '0',
				},
			}}
			{...props}
		/>
	)
}

// Phone number placed next to ClientButton
function ClientPhone() {
	return (
		<div
			data-aos="fade-in"
			css={{
				marginTop: '1rem',
				marginBottom: '1rem',
				display: 'inline-block',
				fontSize: '1.25rem',
				[mq.extraSmall]: {
					// fontSize: '1.0rem',
					// marginBottom: '1rem',
					marginTop: '0',
					marginBottom: '0',
				},
			}}
		>
			Or call {process.env.REACT_APP_PHONE}
		</div>
	)
}

// Navigation Link
function NavLink(props) {
	const match = useMatch(props.to)

	return (
		<Link
			css={[
				{
					textDecoration: 'none',
					color: '#000',
					marginLeft: '0.5rem',
					marginRight: '0.5rem',
					fontFamily: 'Open Sans, serif',
					userSelect: 'none',
					fontSize: '1.15rem',
					fontWeight: '600',
				},
				props.to !== '/'
					? {
							':hover': {
								color: color.button,
							},
					  }
					: null,
				match?.pathname === props.to && match?.pathname !== '/'
					? {
							color: color.button,
					  }
					: null,
			]}
			{...props}
		/>
	)
}

function NavContactButton({href, children, ...props}) {
	return (
		<a
			css={{
				display: 'block',
				textDecoration: 'none',
				borderRadius: '40px',
				backgroundColor: color.button,
				color: '#fff',
				padding: '0.35rem 1.0rem',
				fontSize: '0.9rem',
				boxShadow: '0px 1px 4px 0px rgb(150, 150, 150, 0.75)',
			}}
			href={href}
			{...props}
		>
			{children}
		</a>
	)
}

function NavIconSmall(props) {
	return (
		<div
			css={{
				width: '50px',
				height: '50px',
				display: 'inline-block',
				...props.cssOverride,
			}}
			{...props}
		>
			<a
				css={{
					display: 'inline-block',
				}}
				href={props.href}
			>
				<img
					src={props.src}
					alt={props.alt}
					css={{
						width: '100%',
						height: '100%',
						userSelect: 'none',
					}}
				/>
			</a>
		</div>
	)
}

// Social Media Icons used in Footer
function SocialMediaIcon({
	src,
	srcHovered,
	href,
	width,
	height,
	cssOverride,
	...props
}) {
	const [iconHovered, setIconHovered] = React.useState(false)

	return (
		<a
			href={href}
			target="_blank"
			rel="noreferrer"
			css={{
				width: width,
				height: height,
				display: 'inline-block',
				position: 'relative',
				...cssOverride,
			}}
			onMouseOver={() => setIconHovered(true)}
			onMouseOut={() => setIconHovered(false)}
			{...props}
		>
			<div
				css={{
					position: 'absolute',
					transition: '0.3s ease-in-out',
					background: `url(${src})`,
					backgroundSize: 'cover',
					width: width,
					height: height,
					opacity: iconHovered ? 0 : 1,
				}}
			/>
			<div
				css={{
					position: 'absolute',
					transition: '0.3s ease-in-out',
					background: `url(${srcHovered})`,
					backgroundSize: 'cover',
					width: width,
					height: height,
					opacity: iconHovered ? 1 : 0,
				}}
			/>
		</a>
	)
}

function ServiceContainer(props) {
	return (
		<div
			css={{
				margin: '0 auto',
				maxWidth: '1280px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				[mq.extraSmall]: {
					marginLeft: '1rem',
					marginRight: '1rem',
					flexDirection: 'column',
				},
				[mq.small]: {
					flexDirection: 'column',
				},
				[mq.medium]: {
					gap: '10px',
				},
				[mq.large]: {
					gap: '10px',
				},
			}}
			{...props}
		/>
	)
}

function ServiceContainerElement({service, src, alt, to, ...props}) {
	const [isHovered, setIsHovered] = React.useState(false)
	const {ref, inView} = useInView({
		threshold: 0.6,
		triggerOnce: true,
	})

	return (
		<div
			ref={ref}
			css={{
				maxWidth: '1280px',
				display: 'flex',
				flexDirection: 'column',
				position: 'relative',
				overflow: 'hidden',
				[mq.extraSmall]: {
					margin: '0.5rem auto',
					maxHeight: '140px',
					marginLeft: '2rem',
					marginRight: '2rem',
				},
				[mq.small]: {
					margin: '0.5rem auto',
					maxHeight: '140px',
					minWidth: '320px',
					maxWidth: '500px',
				},
			}}
			{...props}
		>
			<Link
				css={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					position: 'relative',
					overflow: 'hidden',
					transition: '0.3s ease-in-out',
				}}
				to={to}
				onMouseOver={() => setIsHovered(true)}
				onMouseOut={() => setIsHovered(false)}
			>
				{/* Image */}
				<img
					css={{
						top: '0',
						left: '0',
						right: '0',
						width: '100%',
						height: '100%',
						minWidth: '180px',
						transform: isHovered ? `scale(1.03)` : `scale(1.0)`,
						transition: '0.3s ease-in-out',
						display: 'block',
						[mq.extraSmall]: {
							transform: isHovered ? `scale(1.01)` : `scale(1.0)`,
						},
						[mq.small]: {
							transform: isHovered ? `scale(1.01)` : `scale(1.0)`,
						},
					}}
					src={src}
					alt={alt}
				/>
				{/* Transition cover */}
				<div
					css={{
						top: '0',
						position: 'absolute',
						zIndex: '1',
						backgroundColor: '#EAEAEA',
						width: '100%',
						right: inView ? '100%' : '0',
						transition: 'all 0.3s cubic-bezier(0.215, 0.61, 0.325, 1) 0s',
						transitionDelay: '0.4s',
						height: '100%',
					}}
				/>
				{/* Image Tint */}
				<div
					css={{
						position: 'absolute',
						top: '0',
						left: '0',
						right: '0',
						bottom: '0',
						opacity: isHovered ? '0.075' : '0.15',
						borderRadius: '3px',
						transition: '0.3s ease-in-out',
						backgroundColor: '#000',
						width: '100%',
						height: '100%',
						minWidth: '190px',
					}}
				/>

				{/* Service Title */}
				<div
					css={{
						zIndex: '2',
						position: 'absolute',
						top: '88%',
						left: '1rem',
						display: 'inline-block',
						transform: 'translateY(-50%)',
						color: '#fff',
						fontFamily: 'Barlow Condensed, sans-serif',
						fontWeight: '600',
						userSelect: 'none',
						[mq.extraSmall]: {
							fontSize: '1.75rem',
							top: '86%',
						},
						[mq.small]: {
							fontSize: '2.25rem',
							top: '86%',
						},
						[mq.medium]: {
							fontSize: '1.75rem',
							left: '0',
							right: '0',
						},
						[mq.large]: {
							fontSize: '1.75rem',
							left: '0',
							right: '0',
						},
					}}
				>
					{service}
				</div>
			</Link>
		</div>
	)
}

// Content of each page should follow a basic min and max sizes with margins.
function ContentContainer(props) {
	return (
		<div
			css={{
				maxWidth: '800px',
				margin: '3rem auto',
				textAlign: 'left',
			}}
			{...props}
		/>
	)
}

function GalleryImageSmall({src, alt, ...props}) {
	const {ref, inView} = useInView({
		threshold: 0,
		triggerOnce: true,
	})

	return (
		<div
			css={{
				display: 'flex',
				flex: '40%',
				marginTop: '1rem',
				marginBottom: '1rem',
				position: 'relative',
				overflow: 'hidden',
			}}
			{...props}
		>
			{/* Transition cover */}
			<div
				ref={ref}
				css={{
					top: '0',
					position: 'absolute',
					zIndex: '1',
					backgroundColor: '#EAEAEA',
					width: '100%',
					right: inView ? '100%' : '0',
					transition: 'all 0.3s cubic-bezier(0.215, 0.61, 0.325, 1) 0s',
					transitionDelay: '0.4s',
					height: '100%',
				}}
			/>
			<img
				css={{
					width: '100%',
					height: '100%',
					minWidth: '180px',
					boxShadow: '0px 1px 3px 0px rgb(150, 150, 150, 1)',
				}}
				src={src}
				alt="alt"
			/>
		</div>
	)
}

function GalleryImageLarge({src, alt, ...props}) {
	return (
		<div
			css={{
				display: 'flex',
				flex: '40%',
				marginTop: '1rem',
			}}
			{...props}
		>
			<img
				css={{
					width: '100%',
					height: '100%',
					minWidth: '180px',
					boxShadow: '0px 1px 3px 0px rgb(150, 150, 150, 1)',
				}}
				src={src}
				alt={alt}
			/>
		</div>
	)
}

// Color Button matches rest of theme.
function ColorStyledAnchor({href, ...props}) {
	const [onHover, setOnHover] = React.useState(false)

	return (
		<a
			href={href}
			css={{
				userSelect: 'none',
				padding: '0.7rem 1.5rem',
				backgroundColor: onHover ? color.hover : color.button,
				transition: '0.3s ease-in-out',
				borderRadius: '40px',
				cursor: 'pointer',
				textDecoration: 'none',
				color: '#fff',
				boxShadow: '0px 1px 5px 0px rgb(150, 150, 150, 1)',
			}}
			target="_blank"
			rel="noreferrer"
			onMouseOver={() => setOnHover(true)}
			onMouseOut={() => setOnHover(false)}
			{...props}
		>
			{props.children}
		</a>
	)
}

function InfoContentContainer(props) {
	return (
		<ContentContainer
			css={{
				[mq.extraSmall]: {
					marginLeft: '2rem',
					marginRight: '2rem',
				},
				[mq.small]: {
					marginLeft: '3rem',
					marginRight: '3rem',
				},
			}}
			{...props}
		/>
	)
}

function InfoHeader(props) {
	return (
		<div
			css={{
				marginBottom: '1rem',
				fontSize: '2rem',
				fontFamily: 'TiemposHeadlineRegular, serif',
				color: '#333',
				[mq.extraSmall]: {
					textAlign: 'center',
					fontSize: '1.65rem',
				},
				[mq.small]: {
					textAlign: 'center',
				},
			}}
			{...props}
		/>
	)
}

function InfoSubHeader(props) {
	return (
		<div
			css={{
				fontFamily: 'TiemposHeadlineRegular, serif',
				fontSize: '1.5rem',
				marginBottom: '1rem',
				[mq.extraSmall]: {
					textAlign: 'center',
					fontSize: '1.25rem',
				},
			}}
			{...props}
		/>
	)
}

// General information container
function InfoContent(props) {
	return <div {...props} />
}

function FlexRow(props) {
	return (
		<div
			css={{
				display: 'flex',
				[mq.extraSmall]: {
					flexDirection: 'column',
					marginLeft: '1rem',
					marginRight: '1rem',
				},
				[mq.small]: {
					flexDirection: 'column',
					marginLeft: '1rem',
					marginRight: '1rem',
				},
				[mq.medium]: {
					flexDirection: 'row',
					marginTop: '2rem',
					marginBottom: '2rem',
				},
				[mq.large]: {
					flexDirection: 'row',
					marginTop: '2rem',
					marginBottom: '2rem',
				},
			}}
			{...props}
		/>
	)
}

function Column(props) {
	return (
		<div
			css={{
				[mq.extraSmall]: {
					marginLeft: '1rem',
					marginRight: '1rem',
				},
				[mq.small]: {
					marginLeft: '1rem',
					marginRight: '1rem',
				},
			}}
			{...props}
		/>
	)
}

function FlexColumn({orientation, ...props}) {
	return (
		<div
			css={{
				[mq.extraSmall]: {
					marginTop: '1rem',
					marginBottom: '1rem',
				},
				[mq.small]: {
					marginTop: '1rem',
					marginBottom: '1rem',
				},
				[mq.medium]: {
					marginLeft: orientation === 'right' ? '1rem' : '0',
					marginRight: orientation === 'left' ? '1rem' : '0',
					flex: '1',
				},
				[mq.large]: {
					marginLeft: orientation === 'right' ? '1rem' : '0',
					marginRight: orientation === 'left' ? '1rem' : '0',
					flex: '1',
				},
			}}
			{...props}
		/>
	)
}

function PricingCard({...props}) {
	return (
		<FlexColumn
			css={{
				backgroundColor: color.pricingBackground,
				borderRadius: '20px',
				paddingTop: '1.5rem',
				paddingBottom: '1.5rem',
				paddingLeft: '0.5rem',
				paddingRight: '0.5rem',
				textAlign: 'center',
				boxShadow: '0px 1px 8px 0px rgb(180, 180, 180, 1)',
				[mq.medium]: {
					marginTop: '2rem',
					marginBottom: '2rem',
				},
				[mq.large]: {
					marginTop: '2rem',
					marginBottom: '2rem',
				},
			}}
			{...props}
		/>
	)
}

function PricingTitle({...props}) {
	return (
		<div
			css={{
				fontWeight: '800',
				color: '#fff',
				fontSize: '1.5rem',
				fontFamily: 'Open Sans, sans-serif',
				textTransform: 'uppercase',
				letterSpacing: '-1px',
				textDecoration: 'underline',
			}}
			{...props}
		/>
	)
}

function PricingLabel({...props}) {
	return (
		<div
			css={{
				letterSpacing: '2px',
				marginBottom: '0.5rem',
				fontFamily: 'Open Sans',
				color: '#fff',
			}}
			{...props}
		/>
	)
}

function PricingCircle({children, ...props}) {
	return (
		<div
			css={{
				margin: '1rem auto',
				color: '#fff',
				borderRadius: '50%',
				width: '4rem',
				height: '4rem',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				fontFamily: 'Lato',
				fontSize: '2rem',
				fontWeight: '600',
				border: '1px solid #fff',
				userSelect: 'none',
			}}
			{...props}
		>
			<span
				css={{
					fontFamily: 'Lato',
					fontSize: '0.95rem',
					marginRight: '1px',
				}}
			>
				$
			</span>
			{children}
		</div>
	)
}

// Daycare Images, Grooming Images
function Image({src, alt, ...props}) {
	const {ref, inView} = useInView({
		threshold: 0,
		triggerOnce: true,
	})

	return (
		<div
			css={{
				width: '100%',
				position: 'relative',
				overflow: 'hidden',
			}}
			{...props}
		>
			{/* Transition cover */}
			<div
				ref={ref}
				css={{
					top: '0',
					position: 'absolute',
					zIndex: '1',
					backgroundColor: '#EAEAEA',
					width: '100%',
					right: inView ? '100%' : '0',
					transition: 'all 0.3s cubic-bezier(0.215, 0.61, 0.325, 1) 0s',
					transitionDelay: '0.4s',
					height: '100%',
				}}
			/>
			<img
				css={{
					width: '100%',
				}}
				src={src}
				alt={alt}
			/>
		</div>
	)
}

// Boarding Room Row
function BoardingRoomContainer(props) {
	return (
		<div
			css={{
				display: 'flex',
				flexWrap: 'wrap',
				flexDirection: 'row',
				maxWidth: '800px',
				margin: '4rem auto',
				[mq.extraSmall]: {
					flexDirection: 'column',
					marginLeft: '2rem',
					marginRight: '2rem',
				},
				[mq.small]: {
					flexDirection: 'column',
					marginLeft: '3rem',
					marginRight: '3rem',
				},
			}}
			{...props}
		/>
	)
}

// Boarding Room Image
function BoardingRoomImage({src, isHovered, alt, orientation, ...props}) {
	const {ref, inView} = useInView({
		threshold: 0,
		triggerOnce: true,
	})

	return (
		<div
			ref={ref}
			css={{
				position: 'relative',
				flex: '1',
				flexBasis: '40%',
				marginLeft: orientation === 'right' ? '2rem' : '0',
				marginRight: orientation === 'left' ? '2rem' : '0',
				overflow: 'hidden',
				[mq.extraSmall]: {
					marginLeft: '0rem',
					marginRight: '0rem',
					order: 0,
				},
				[mq.small]: {
					marginLeft: '0rem',
					marginRight: '0rem',
					order: 0,
				},
			}}
			{...props}
		>
			{/* Transition cover */}
			<div
				css={{
					top: '0',
					position: 'absolute',
					zIndex: '1',
					backgroundColor: '#EAEAEA',
					width: '100%',
					right: inView ? '100%' : '0',
					transition: 'all 0.3s cubic-bezier(0.215, 0.61, 0.325, 1) 0s',
					transitionDelay: '0.4s',
					height: '100%',
				}}
			/>
			<img
				css={{
					width: '100%',
					height: '100%',
					display: 'block',
				}}
				src={src}
				alt={alt}
			/>
		</div>
	)
}

// Boarding room info contains room name and content
function BoardingRoomInfo({orientation, ...props}) {
	return (
		<div
			css={{
				flex: '1',
				flexBasis: '40%',
				textAlign: 'left',
				marginLeft: orientation === 'right' ? '2rem' : '0',
				marginRight: orientation === 'left' ? '2rem' : '0',
				[mq.extraSmall]: {
					fontSize: '0.9rem',
					marginLeft: '0rem',
					marginRight: '0rem',
					order: 1,
				},
				[mq.small]: {
					fontSize: '0.9rem',
					marginLeft: '0rem',
					marginRight: '0rem',
					order: 1,
				},
			}}
		>
			{props.children}
		</div>
	)
}

// Boarding room name
function BoardingRoomName(props) {
	return (
		<div
			css={{
				fontSize: '2rem',
				fontFamily: 'TiemposHeadlineMedium, serif',
				[mq.extraSmall]: {
					textAlign: 'center',
					fontSize: '1.65re m',
					marginTop: '1rem',
					marginBottom: '1rem',
				},
				[mq.small]: {
					textAlign: 'center',

					fontSize: '1.85rem',
					marginTop: '1rem',
					marginBottom: '1rem',
				},
			}}
			{...props}
		/>
	)
}

// Boarding room content
function BoardingRoomContent(props) {
	return (
		<div
			css={{
				margin: '1rem auto',
				[mq.small]: {
					textAlign: 'center',
				},
				[mq.extraSmall]: {
					textAlign: 'center',
				},
			}}
			{...props}
		/>
	)
}

// FAQ Title
function FAQTitle(props) {
	return (
		<div
			css={{
				fontSize: '2rem',
				fontFamily: 'TiemposHeadlineRegular, serif',
				textAlign: 'center',
				[mq.extraSmall]: {
					fontSize: '1.65rem',
				},
				[mq.small]: {
					fontSize: '2rem',
				},
			}}
		>
			Frequently Asked Questions
		</div>
	)
}

const animateShowFAQAnswer = keyframes({
	'0:': {
		height: '0',
	},
	'100%:': {
		height: 'auto',
	},
})

// FAQ Question
function FAQQuestion({question, answer}) {
	const [showAnswer, setShowAnswer] = React.useState(false)

	return (
		<div
			css={{
				position: 'relative',
				marginTop: '1rem',
				marginBottom: '1rem',
				height: 'auto',
				backgroundColor: '#f5f5f5',
			}}
		>
			{/* Button */}
			<div
				css={{
					position: 'absolute',
					right: '1rem',
					top: '1.5rem',
				}}
			>
				<div
					css={{
						borderRadius: '100%',
						padding: '0.5rem',
						transform: showAnswer ? 'rotate(45deg)' : 'rotate(0deg)',
						transition: '0.3s ease-in-out',
						margin: '0 auto',
						verticalAlign: 'middle',
						width: '18px',
						height: '18px',
						textAlign: 'center',
						cursor: 'pointer',
						position: 'relative',
					}}
					onClick={() => setShowAnswer(!showAnswer)}
				>
					<img
						src={plusIcon}
						alt="plus-icon"
						css={{
							width: '100%',
							height: '100%',
							userSelect: 'none',
						}}
					/>
				</div>
			</div>

			{/* Question */}
			<div
				css={{
					width: '73%',
					fontFamily: 'Open Sans, sans-serif',
					paddingTop: '2rem',
					paddingBottom: '2rem',
					marginLeft: '2rem',
					marginRight: '2rem',
					fontSize: '1rem',
					borderRadius: '3px',
					[mq.extraSmall]: {
						fontSize: '0.8rem',
						marginLeft: '1rem',
					},
					[mq.small]: {
						fontSize: '0.8rem',
					},
				}}
			>
				{question}
			</div>

			{/* Answer */}
			<div
				css={{
					paddingBottom: showAnswer ? '1rem' : '0',
					marginLeft: '2rem',
					marginRight: '2rem',
					fontFamily: 'Open Sans, sans-serif',
					fontSize: '1rem',
					animation: `${animateShowFAQAnswer} 0.3s ease-in-out forwards`,
					maxHeight: showAnswer ? '20rem' : '0',
					overflow: 'hidden',
					transition: '0.3s ease-in-out',
					[mq.extraSmall]: {
						fontSize: '0.8rem',
						marginLeft: '1rem',
						marginRight: '1rem',
					},
					[mq.small]: {
						fontSize: '0.8rem',
					},
				}}
			>
				{answer}
			</div>
		</div>
	)
}

function Form(props) {
	return (
		<form
			css={{
				margin: '0 auto',
				width: '100%',
				[mq.extraSmall]: {},
				[mq.small]: {
					maxWidth: '400px',
				},
				[mq.medium]: {
					maxWidth: '500px',
				},
				[mq.large]: {
					maxWidth: '500px',
				},
			}}
			{...props}
		/>
	)
}

function FormGroup(props) {
	return (
		<div
			css={{
				display: 'flex',
				flexDirection: 'row',
				margin: '0 auto',
				[mq.extraSmall]: {
					marginLeft: '1rem',
					marginRight: '1rem',
				},
			}}
			{...props}
		/>
	)
}

function Label({htmlFor, ...props}) {
	return (
		<label
			htmlFor={htmlFor}
			css={{
				display: 'block',
				fontFamily: 'Lora, serif',
				paddingLeft: '4px',
				marginBottom: '0.5rem',
				[mq.extraSmall]: {
					fontSize: '0.9rem',
				},
				[mq.small]: {
					fontSize: '1rem',
				},
				[mq.medium]: {
					fontSize: '1.1rem',
				},
				[mq.large]: {
					fontSize: '1.1rem',
				},
			}}
			{...props}
		/>
	)
}

function Input({name, label, placeholder, register, errors, ...props}) {
	return (
		<div
			id={label}
			aria-label={label}
			css={{
				flex: '1',
				height: '108px',
				width: '100%',
			}}
			{...props}
		>
			<div
				css={{
					display: 'flex',
					alignContent: 'center',
					flexDirection: 'row',
				}}
			>
				<Label htmlFor={label}>{label}</Label>
			</div>
			<input
				css={{
					width: '100%',
					border: '1px solid hsl(0, 0%, 80%)',
					borderRadius: '4px',
					outline: 'none',
					padding: '0',
					fontFamily: 'Open Sans, sans-serif',
					fontSize: '1rem',
					textIndent: '10px',
					height: '38px',
					boxSizing: 'border-box',
					'::-webkit-input-placeholder': {
						fontSize: '0.85rem',
					},
					[mq.extraSmall]: {
						fontSize: '0.9rem',
					},
					':focus': {
						borderColor: color.inputFocus,
						boxShadow: `0 0 0 1px ${color.inputFocus}`,
					},
				}}
				aria-invalid={errors[name] ? 'true' : 'false'}
				placeholder={placeholder}
				{...register}
			/>
			{errors[name] && <InputError>{errors[name].message}</InputError>}
		</div>
	)
}

function InputError({...props}) {
	return (
		<div
			css={{
				color: 'red',
				paddingTop: '2px',
				paddingLeft: '3px',
				[mq.extraSmall]: {
					fontSize: '0.8rem',
				},
				[mq.small]: {
					fontSize: '0.8rem',
				},
				[mq.medium]: {
					fontSize: '0.8rem',
				},
				[mq.large]: {
					fontSize: '0.8rem',
				},
			}}
			{...props}
		/>
	)
}

function ControllerFullContainer(props) {
	return (
		<div
			css={{
				flex: '1',
				height: '108px',
				width: '100%',
			}}
			{...props}
		/>
	)
}

function ControllerHalfContainer({orientation, ...props}) {
	return (
		<div
			css={{
				flex: '1',
				height: '108px',
				width: '100%',
				marginRight: orientation === 'left' ? '1rem' : '0',
				marginLeft: orientation === 'right' ? '1rem' : '0',
			}}
			{...props}
		/>
	)
}

function SelectContainer(props) {
	return (
		<div
			css={{
				flex: '1',
				width: '100%',
				height: '108px',
			}}
			{...props}
		/>
	)
}

// React (Native)Select that has width adjusted to fit form.
function Select({
	name,
	label,
	defaultValue,
	errors,
	options,
	value,
	onChange,
	...props
}) {
	return (
		<div
			css={{
				// flex: '1',
				width: '100%',
				height: '68px',
				[mq.extraSmall]: {height: '66px'},
				[mq.small]: {height: '66px'},
				[mq.medium]: {height: '68px'},
				[mq.large]: {height: '68px'},
			}}
		>
			<Label htmlFor={label}>{label}</Label>
			<NativeSelect
				options={options}
				value={value}
				onChange={onChange}
				defaultValue={defaultValue}
				{...props}
			/>
			{errors[name] && <InputError>{errors[name].message}</InputError>}
		</div>
	)
}

function TextArea({name, label, register, placeholder, errors, ...props}) {
	return (
		<div
			css={{
				height: '108px',
				width: '100%',
			}}
			{...props}
		>
			<Label>{label}</Label>
			<textarea
				aria-label={label}
				css={{
					width: '100%',
					outline: 'none',
					border: '1px solid hsl(0, 0%, 80%)',
					borderRadius: '4px',
					padding: '0',
					resize: 'none',
					fontFamily: 'Open Sans, sans-serif',
					fontSize: '1rem',
					height: '45px',
					textIndent: '10px',
					paddingTop: '5px',
					boxSizing: 'border-box',
					':focus': {
						borderColor: color.inputFocus,
						boxShadow: `0 0 0 1px ${color.inputFocus}`,
					},
				}}
				aria-invalid={errors[name] ? 'true' : 'false'}
				placeholder={placeholder}
				{...register}
			/>
			{errors[name] && <InputError>{errors[name].message}</InputError>}
		</div>
	)
}

function SubmitButton(props) {
	const [onHover, setOnHover] = React.useState(false)

	return (
		<button
			css={{
				marginTop: '2rem',
				outline: 'none',
				border: 'none',
				borderRadius: '40px',
				color: '#fff',
				cursor: 'pointer',
				padding: '0.7rem 1.5rem',
				backgroundColor: onHover ? color.hover : color.button,
				transition: '0.3s ease-in-out',
				display: 'inline-block',
				fontFamily: 'Open Sans, sans-serif',
				fontSize: '0.9rem',
				boxShadow: '0px 1px 8px 0px rgb(150, 150, 150, 1)',
			}}
			onMouseOver={() => setOnHover(true)}
			onMouseOut={() => setOnHover(false)}
			{...props}
		/>
	)
}

export {
	Container,
	TitleSection,
	TitleContainer,
	TitleHeader,
	TitleContent,
	TitleImageContainer,
	ClientSection,
	ClientHeader,
	ClientButtonContainer,
	ClientPhoneContainer,
	ClientLink,
	ClientButton,
	ClientPhone,
	ColorStyledAnchor,
	SocialMediaIcon,
	NavLink,
	NavContactButton,
	NavIconSmall,
	ControllerFullContainer,
	ControllerHalfContainer,
	ServiceContainer,
	ServiceContainerElement,
	GalleryImageSmall,
	GalleryImageLarge,
	InfoContentContainer,
	InfoHeader,
	InfoSubHeader,
	InfoContent,
	Column,
	FlexRow,
	FlexColumn,
	PricingCard,
	PricingCircle,
	PricingTitle,
	PricingLabel,
	Image,
	BoardingRoomContainer,
	BoardingRoomImage,
	BoardingRoomInfo,
	BoardingRoomName,
	BoardingRoomContent,
	FAQTitle,
	FAQQuestion,
	Form,
	FormGroup,
	Input,
	InputError,
	Label,
	TextArea,
	SelectContainer,
	Select,
	SubmitButton,
}
